<template>
  <div style="" sm="12" md="8">
    <portal to="title-page">
      <h1>Reservacion : {{reservation ? reservation.code : 'Por favor espere'}}</h1>
    </portal>
    <Loading :active="loading"/>
    <v-row v-if="reservation">
      <v-col sm="12" md="8">
        <v-expansion-panels multiple v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="v-card__title d-flex" style="padding: 0">
              <v-icon class="green--text" v-if="rComputed.confirmationInfo" >{{mdiCheckCircle}}</v-icon> Datos de la reservacion
              <span class="ml-auto primary--text">${{total}}<small>{{rComputed.currency}}</small></span>
              </h3>
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content class="pt-3">
              <Reservation :reservation="reservation"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header><h3 class="v-card__title" style="padding: 0">Guías</h3></v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content class="pt-3">
              <Guides :reservation="reservation"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header><h3 class="v-card__title" style="padding: 0">Tarifas y Precios</h3></v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content class="pt-3">
              <Rates :reservation="reservation"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header><h3 class="v-card__title" style="padding: 0">Extras</h3></v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content class="pt-3">
              <Extras :reservation="reservation"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import Vue from "vue";
  import ReservationModel from "@/models/Reservation";
  import Rates from "@/components/reservations/Rates";
  import Guides from "@/components/diver/Guides"
  import Loading from "@/components/Loading";
  import Reservation from "@/components/diver/Reservation";
  import {NumberFormat} from "@/helpers/NumberFormat";
  import Extras from "@/components/reservations/extras/Extras";
  Vue.use('Loading', Loading);
  
  export default {
    name:'TaquillaReservation',
    components:{Reservation, Rates, Guides, Extras},
    data() {
      return {
        reservationId:this.$route.params.id,
        reservationModel:new ReservationModel(),
        reservation:null,
        unsubscribe:null,
        panel:[0],
        loading:true,
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'Divers')
    },
    mounted() {
      this.unsubscribe = this.reservationModel.getById(this.reservationId).onSnapshot(snap => {
        this.reservation = snap.data()
        this.reservation.setPayments()
        this.loading = false;
      })
    },
    methods:{
    },
    computed:{
      rComputed() {
        let r = {...this.reservation};
        r.date = this.$moment.unix(r.date.seconds).format('DD-MMMM-YYYY');
        r.createdDate = this.$moment.unix(r.createdDate.seconds).format('DD-MMMM-YYYY h:mm:ss a');
        return r;
      },
      total() {
        return NumberFormat.format(this.reservation.total, 2, ',', '.');
      }
    },
  }
</script>