<template>
  <v-form
    ref="form"
    v-model="validForm"
    lazy-validation
  >
    <Loading :active="loading" :fullPage="true" />
    
    <div class="mb-3">
        <v-text-field v-for="(g, index) in nameGuides" :key="index" label="Nombre del Guía"
        :rules="[rules.required]" v-model="nameGuides[index]" type="text">
        </v-text-field>
        <v-divider/>
    </div>
    <v-btn :disabled="!validForm"
        small
        color="primary"
        @click="update"
        dark
    >
        <v-icon>{{mdiContentSave}}</v-icon>
        Guardar
    </v-btn>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="-1">
    {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>

</template>
<script>
  import { mdiContentSave, mdiClose, mdiPencil } from "@mdi/js"
  import Reservation from "@/models/Reservation"

  export default {
    name:"Guides",
    props:['reservation'],
    components: { },
    data() {
      return {
        Reservation: new Reservation,
        nameGuides: {...this.reservation.nameGuides},
        mdiContentSave:mdiContentSave,
        mdiClose: mdiClose,
        mdiPencil: mdiPencil,
        loading: false,
        validForm: true,
        rDate: this.$moment.unix(this.reservation.date.seconds).format('YYYY-MM-DD'),
        rules:{
          required: v => !!v || 'El campo es obligatorio',
        },
        snackbar:{
          show:false,
          color:'',
          message:''
        },
      }
    },
    methods:{
      async update() {

        if (this.$refs.form.validate()) {

          let loading = this.$loading.show();
          for( let index = 0; index <= this.nameGuides.length; index ++){           
            if(this.reservation.nameGuides[index] == ""){
              loading.hide()
              this.snackbar.show = true;
              this.snackbar.color = 'red';
              this.snackbar.message = `No puede haber datos vacíos`;
              break
            } else{
              this.nameGuides.push = this.nameGuides
            }
          }
          try{
            await this.Reservation.updateWithHistory(
              this.reservation.id,
              {nameGuides:this.nameGuides},
              {nameGuides: this.reservation.nameGuides}
            )
            console.log('Agregado al historial¡¡¡')
            loading.hide()
            console.log('Éxito¡¡')
            this.snackbar.show = true;
            this.snackbar.color = 'green';
            this.snackbar.message = `Datos actualizados correctamente`;
          } catch(err){
            console.log(err);
            loading.hide()
            this.snackbar.show = true;
            this.snackbar.color = 'red';
            this.snackbar.message = `Error al actualizar datos, por favor contacte al Administrador si el problema persiste`
          }
        }
      },
    }
  }
</script>