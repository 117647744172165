<template>
  <div>
    <v-form
      ref="form"
      v-model="formDataReservation"
      lazy-validation
    >
      <Loading :active="loadingFormDataReservation" :fullPage="true" />
      <v-row>
        <v-col sm="12" md="12" class="d-flex">
          <small>Creada: {{rComputed.createdDate}}</small>
        </v-col>
        <v-col sm="12" md="12" class="d-flex my-4">
          <v-divider/>
        </v-col>
        <v-col sm="12" md="12" class="d-flex flex-wrap">
          <div class="">
            <small class="d-block">Status</small>
            <BtnStatus
              :icon="rComputed.status == 'checkin' || rComputed.status == 'noshow' ? '' : ''"
              :status="rComputed.status"
              :disabled="rComputed.status == 'checkin' || rComputed.status == 'noshow'"
            />
          </div>
          <div class="ml-2">
            <small class="d-block">Pago</small>
            <BtnPaymentStatus 
              
              :status="rComputed.paymentStatus"
              :balance="reservation.balance"
              />
          </div>
          <div class="ml-2">
            <small class="d-block">Acciones</small>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-menu-down}</v-icon>
                  Seleccione
                </v-btn>
              </template>
              <v-list class="cursor-pointer">
                <v-list-item >
                  <v-list-item-title >Alguna accion</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col sm="12" md="12" class="d-flex my-4">
          <v-divider/>
        </v-col>
        <v-col sm="12" md="8" class="my-2">
          <v-text-field
            v-model="rComputed.tour"
            label="Tour"
            required
            readonly
            dense
          ></v-text-field>
        </v-col>
        <v-col sm="12" md="4" class="my-2">
          <v-text-field
            v-model="rComputed.code"
            label="Codigo"
            required
            readonly
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" md="6">
          <v-text-field
            v-model="rDate"
            label="Fecha"
            prepend-icon="mdi-calendar"
            readonly
          ></v-text-field>
          <!-- <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="rDate"
                label="Fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              
              v-model="rDate"
              @input="menuDate = false"
              
            ></v-date-picker>
          </v-menu> -->
        </v-col>
      </v-row>
      <v-row v-if="rComputed.panel === 'divers'">
        <v-col sm="6" md="3" lg="2">
          <v-text-field
            v-model="rComputed.guides"
            label="Guías"
            prepend-icon="mdi-diving-helmet"
            type="number"
            min="0"
            readonly
            dense
          ></v-text-field>
        </v-col>
        <v-col sm="6" md="3" lg="2">
          <v-text-field
            v-model="rComputed.adults"
            label="Clientes"
            prepend-icon="mdi-diving-scuba"
            type="number"
            min="0"
            readonly
            dense
          ></v-text-field>
        </v-col>
        <v-col sm="6" md="3" lg="2">
          <v-text-field
            v-model="rComputed.immersions"
            label="Inmersiones extras"
            prepend-icon="mdi-diving-scuba-tank-multiple"
            type="number"
            min="0"
            readonly
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-textarea
        v-if="rComputed.confirmationInfo"
        v-model="rComputed.confirmationDescription"
        name="input-7-1"
        label="Texto de confirmación"
        rows="2"
        :prepend-icon="mdiMessageAlertOutline"
      ></v-textarea>
      <!-- <v-btn
        small
        color="primary"
        dark
      >
        <v-icon>mdi-content-save</v-icon>
        Guardar
      </v-btn> -->
    </v-form>
  </div>
</template>
<script>
  import {NumberFormat} from "@/helpers/NumberFormat";
  import BtnStatus from "@/components/reservations/BtnStatus";
  import BtnPaymentStatus from "@/components/reservations/BtnPaymentStatus";
  export default {
    name:'ReservationDiver',
    props:['reservation'],
    components:{BtnStatus, BtnPaymentStatus},
    data() {
      return {
        panel: [0],
        formDataReservation: false,
        loadingFormDataReservation: false,
        rDate: this.$moment.unix(this.reservation.date.seconds).format('YYYY-MM-DD'),
      }
    },
    computed:{
      rComputed() {
        let r = {...this.reservation};
        r.date = this.$moment.unix(r.date.seconds).format('DD-MMMM-YYYY');
        r.createdDate = this.$moment.unix(r.createdDate.seconds).format('DD-MMMM-YYYY h:mm:ss a');
        return r;
      },
      total() {
        return NumberFormat.format(this.reservation.total, 2, ',', '.');
      }
    },
  }
</script>